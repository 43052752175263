@import "tools";

@mixin underline-border-bottom($color) {
    text-decoration: none;
    color: $color;
    padding-bottom: 4px;
    border-bottom: 1px solid $color;
    font-size: $font-size-body-default;
    letter-spacing: $letter-spacing-one;
    line-height: 16px;

    &:hover {
        border-bottom: none;
    }
}

.block-text,
.block-text div {
    letter-spacing: $no-letter-spacing;
    font-size: $font-size-body-m;
    line-height: 20px;

    @include breakpoint($breakpoint-medium) {
        font-size: $font-size-body-l;
        line-height: 22px;
    }

    a {
        @include underline-border-bottom($color-primary-dark);

        font-size: $font-size-body-default;
        line-height: 16px;
        letter-spacing: $letter-spacing-one;
        text-transform: uppercase;
    }
}

.editorial-block-title {
    font-size: $font-size-heading-l;
    line-height: 34px;
    font-family: $font-secondary;
    text-transform: uppercase;
    font-weight: $font-weight-light;

    @include breakpoint($breakpoint-medium) {
        font-size: $font-size-heading-xl;
        line-height: 38px;
    }
}

.editorial-block-title-2 {
    font-family: $font-secondary;
    letter-spacing: $no-letter-spacing;
    font-weight: $font-weight-regular;
    font-size: $font-size-body-xl;
    line-height: 20px;

    @include breakpoint($breakpoint-medium) {
        font-size: $font-size-heading-default;
        line-height: 28px;
    }
}

.editorial-block-title-3 {
    font-family: $font-primary;
    letter-spacing: $no-letter-spacing;
    font-weight: $font-weight-regular;
    font-size: $font-size-body-m;
    line-height: 20px;
    text-transform: uppercase;
    @include breakpoint($breakpoint-medium) {
        font-size: $font-size-body-l;
        line-height: 22px;
        text-transform: uppercase;
    }
}

.editorial-block-tag {
    font-family: $font-primary;
    letter-spacing: $no-letter-spacing;
    font-weight: $font-weight-regular;
    font-size: $font-size-body-m;
    line-height: 20px;
    text-transform: uppercase;
}

.editorial-block-intro,
.editorial-block-text {
    font-size: $font-size-body-m;
    line-height: 20px;
    letter-spacing: $no-letter-spacing;

    @include breakpoint($breakpoint-medium) {
        font-size: $font-size-body-l;
        line-height: 22px;
    }

    a {
        @include underline-border-bottom($color-primary-dark);

        font-size: $font-size-body-default;
        line-height: 16px;
        letter-spacing: $letter-spacing-one;
        text-transform: uppercase;
    }
}

.editorial-immersive div {
    letter-spacing: $no-letter-spacing;
    font-size: $font-size-body-m;
    line-height: 20px;

    a {
        @include underline-border-bottom($color-white);

        font-size: $font-size-body-default;
        line-height: 16px;
        letter-spacing: $letter-spacing-one;
        text-transform: uppercase;
    }
}

.editorial-block-actions,
.editorial-block-credits,
.editorial-block-paragraph {
    font-size: $font-size-body-m;
    line-height: 20px;
    letter-spacing: $no-letter-spacing;

    @include breakpoint($breakpoint-medium) {
        font-size: $font-size-body-l;
        line-height: 22px;
    }
}

.grid-cta-button,
.grid-vto-cta-button {
    font-size: $font-size-body-default;
    line-height: 16px;
    letter-spacing: $letter-spacing-one;
    text-transform: uppercase;
}
